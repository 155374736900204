// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '80'
  }, {
    prop: 'shop',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'product_info',
    label: '',
    Object: 'value',
    width: '400'
  }, {
    prop: 'refund_amount',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'refund_reason',
    label: '',
    Object: 'value',
    width: '200'
  }, {
    prop: 'user',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'host',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'user_order',
    label: '',
    Object: 'value',
    width: '200'
  }, {
    prop: 'status',
    label: '',
    Object: 'value',
    width: '180'
  }],
  orderViewItem: [{
    prop: 'status',
    back: true
  }, {
    prop: 'parent_user_order'
  }, {
    prop: 'shop'
  }, {
    prop: 'submitted_by'
  }, {
    prop: 'created_at',
    date: true,
    format: ['YYYY年mm月dd日 HH:MM', 'YYYY-mm-dd HH:MM']
  }, {
    prop: 'updated_at',
    date: true,
    format: ['YYYY年mm月dd日 HH:MM', 'YYYY-mm-dd HH:MM']
  }, {
    prop: 'refund_amount'
  }, {
    prop: 'refund_item_count'
  }, {
    prop: 'item_status'
  }, {
    prop: 'refund_reason'
  }, {
    prop: 'remarks'
  }, {
    prop: 'images',
    image: true,
    span: 24
  }, {
    prop: 'related_item',
    table: true,
    span: 24,
    tableItem: [{
      prop: 'image',
      img: true,
      label: '',
      height: '100px',
      width: '120'
    }, {
      prop: 'shop_item',
      label: '',
      Object: 'value',
      width: '300'
    }, {
      prop: 'shop_item_stock',
      label: '',
      Object: 'value',
      width: '300'
    }, {
      prop: 'preferences',
      label: '',
      Object: 'value',
      width: '300'
    }, {
      prop: 'sku',
      label: '',
      Object: 'value',
      width: '160'
    }, {
      prop: 'count',
      label: '',
      width: '160',
      Object: 'value'
    }, {
      prop: 'status',
      back: true,
      label: '',
      width: '150',
      Object: 'value'
    }]
  }],
  customerViewItem: [{
    prop: 'customer_name'
  }, {
    prop: 'contact_number'
  }, {
    prop: 'customer_id'
  }, {
    prop: 'nickname'
  }, {
    prop: 'email'
  }],
  hostViewItem: [{
    prop: 'host_name'
  }, {
    prop: 'contact_number'
  }, {
    prop: 'customer_id'
  }, {
    prop: 'email'
  }]
}
