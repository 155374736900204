<template>
  <div class="managerAfterOrderView">
    <view-frame :titleObject="titleObject" :buttonObj="buttonObj">
      <template #rightBtn="scope">
        <!-- <el-button v-show="item.action !== 'view'" v-for="(item, index) in custName.member_actions.default_actions" :key="'default_actions' + index" type="primary" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
        <el-button v-for="(item, index) in custName.member_actions.custom_actions" :key="'custom_actions' + index" type="primary" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button> -->
        <template v-if="custName && custName.member_actions && custName.member_actions.default_actions">
          <el-button v-show="item.action !== 'view'" v-for="(item, index) in custName.member_actions.default_actions" :key="'default_actions' + index" type="primary" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
        </template>
        <template v-if="custName && custName.member_actions && custName.member_actions.custom_actions">
          <el-button v-for="(item, index) in custName.member_actions.custom_actions" :key="'custom_actions' + index" type="primary" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
        </template>
      </template>
      <template>
        <el-card>
          <el-row>
            <el-col :span="24">
            <span class="view__text">{{$t('form.order.orderInfo')}}</span>
            <el-card class="animate__animated animate__fadeInDown" shadow="hover">
              <custome-table tableType="object" :custSpan="4" :data="custName.order_information" :dataItem="orderViewItem" @forwardToPage="forwardToPage" @createTableForwardToPage="handleOrderBasicInfoTableForwardToPage"></custome-table>
            </el-card>
            </el-col>
          </el-row>
          <el-row class="margintop20" :gutter="20">
            <el-col :span="12">
              <span class="view__text">{{$t('form.order.custInfo')}}</span>
              <el-card class="animate__animated animate__fadeInLeft" shadow="hover">
                <custome-table tableType="object" :custSpan="8" :data="custName.customer_information" :dataItem="customerViewItem"></custome-table>
              </el-card>
            </el-col>
            <el-col :span="12">
              <span class="view__text">{{$t('form.order.hostInfo')}}</span>
              <el-card class="animate__animated animate__fadeInRight" shadow="hover">
                <custome-table tableType="object" :custSpan="12" :data="custName.host_information" :dataItem="hostViewItem"></custome-table>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="margintop20">
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{$t('form.common.changeHistory')}}</span>
              <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                 <span class="view__text"></span>
                 <createTable :autoWidth="true" :tableDataItem="historyTableItem" :operateVisible="false" :tableData="historyTableData"></createTable>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </template>
    </view-frame>
    <dialogEffect v-if="dialogVisible" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="doNextConfirm"></dialogEffect>
  </div>
</template>

<script>
import { getAfterSaleOrderDetails } from '@/services/order'
import history from '@/common/data/jsonData'
import { getHistoryList } from '@/services/resource'
import createTable from '@/components/createTable'
import viewFrame from '@/components/viewFrame'
import customeTable from '@/components/customeTable'
import loading from '@/utils/loading.json'
import dialogEffect from '@/components/dialogEffect'
import jsonData from './jsonData'
// import Utils from '@/utils/Utils'
import { mixin } from '@/mixins/viewDiolog'

export default {
  components: {
    createTable,
    viewFrame,
    customeTable,
    dialogEffect
  },
  mixins: [mixin],
  data () {
    return {
      validateVisible: false,
      buttonObj: [],
      titleObject: {
        show: true
      },
      custName: {},
      formData: {},
      modifyData: {},
      type: '',
      orderViewItem: jsonData.orderViewItem,
      customerViewItem: jsonData.customerViewItem,
      hostViewItem: jsonData.hostViewItem,
      historyTableItem: history.historyTableItem,
      // dialogData: [],
      // batchData: [],
      // dialogVisible: false,
      historyTableData: []
    }
  },
  beforeRouteLeave (to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    this.validateVisible = false
    // const dataOperate = JSON.parse(sessionStorage.getItem('dataOperate'))
    // if (to.name === 'managerProductEdit') {
    //   sessionStorage.setItem('dataOperate', JSON.stringify({ id: dataOperate.id, type: 'edit', obj: dataOperate.obj }))
    // }
    // if (to.name === 'managerProductCopy') {
    //   sessionStorage.setItem('dataOperate', JSON.stringify({ id: dataOperate.id, type: 'copy', obj: dataOperate.obj }))
    // }
    // if (!(to.name === 'managerProductEdit' || to.name === 'managerProductCopy')) {
    //   sessionStorage.setItem('dataOperate', JSON.stringify({ id: undefined, type: undefined, obj: undefined }))
    // }
    // sessionStorage.setItem('dataOperate', JSON.stringify({ id: undefined, type: undefined, obj: undefined }))
    next()
  },
  created () {
    this.init()
  },
  methods: {
    // 初始化
    async init () {
      // const dataOperate = JSON.parse(sessionStorage.getItem('salesOrderdataOperate'))
      // this.modifyData = dataOperate
      // const { obj, type, id } = dataOperate
      // Object.assign(this.titleObject, { title: this.$t('form.order.order') + ' No. ', data: obj.order_id })
      // this.custName = obj
      // this.type = type
      this.$store.state.app.loading = this.$loading(loading)
      const res = await getAfterSaleOrderDetails(this.$route.params.id)
      if (res.status === 200) {
        this.custName = res.data.after_sales_order
        Object.assign(this.titleObject, { title: this.$t('form.order.order') + ' No. ', data: this.custName.order_id })
      }
      this.$store.state.app.loading.close()
      // if (this.custName.order_information && this.custName.order_information.related_item) {
      //   Object.keys(this.custName.order_information.related_item).forEach(item => {
      //     const index = this.orderViewItem[12].tableItem.findIndex(fditem => fditem.prop === item)
      //     if (index !== -1) {
      //       this.orderViewItem[12].tableItem[index].label = this.custName.order_information.related_item[item].name
      //     }
      //   })
      // }
      // //  获取历史操作
      // getHistoryList({ item_type: 'AfterSalesOrder', item_id: this.$route.params.id }).then(res => {
      //   if (res.status === 200) {
      //     this.historyTableData = res.data.versions
      //   }
      // })
      this.getDetail()
    },
    // 点击操作按钮
    async forwardToPage (data, name) {
      if (name !== 'parent_user_order') return
      this.$router.push({
        name: 'ordersManagerUserOrdersDetail',
        params: {
          id: data.id
        }
      })
    },
    getDetail () {
      if (this.custName.order_information && this.custName.order_information.related_item) {
        Object.keys(this.custName.order_information.related_item).forEach(item => {
          const index = this.orderViewItem[12].tableItem.findIndex(fditem => fditem.prop === item)
          if (index !== -1) {
            this.orderViewItem[12].tableItem[index].label = this.custName.order_information.related_item[item].name
          }
        })
      }
      //  获取历史操作
      getHistoryList({ item_type: 'AfterSalesOrder', item_id: this.$route.params.id }).then(res => {
        if (res.status === 200) {
          this.historyTableData = res.data.versions
        }
      })
    },
    // handleCusClick (data) {
    //   this.batchData = data
    //   if (data.extra_params && data.extra_params.length > 0) {
    //     this.dialogData = data.extra_params
    //     this.dialogVisible = true
    //   } else {
    //     Utils.custOperate(data, this)
    //   }
    // },
    // doNextConfirm (data) {
    //   Utils.custOperate(this.batchData, this, {}, data)
    // },
    async handleClick (type, data) {
      // this.$store.state.app.loading = this.$loading(loading)
      // let obj = {}
      // if (type?.action === 'view') {
      //   const res = await getOrderDetails(data.id.value)
      //   if (res.status === 200) {
      //     obj = res.data.shop_payment
      //   }
      // }
      // this.$store.state.app.loading.close()
      // sessionStorage.setItem('salesOrderdataOperate', JSON.stringify({ id: data && data.id ? data.id : undefined, type: type.action, obj, data }))
      // this.$router.push({
      //   name: 'managerUserOrderView'
      // })
    },
    async dialogSearch () {
      // const res = await getAfterSaleOrderDetails(this.$route.params.id)
      // // loading.close()
      // if (res.status === 200) {
      //   this.$nextTick(() => {
      //     this.custName = res.data.after_sales_order
      //   })
      // }
      this.init()
    },
    gobackRouter () {
      this.$router.go(-1)
    },
    handleOrderBasicInfoTableForwardToPage (data, name) {
      if (name === 'shop_item') {
        this.$router.push({
          name: 'goodsManagerProductDetail',
          params: {
            id: data.id
          }
        })
      } else if (name === 'shop_item_stock') {
        this.$router.push({
          name: 'goodsManagerVariationsDetail',
          params: {
            id: data.id
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scope>
</style>
